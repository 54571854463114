import React from 'react';
import { navigate } from 'gatsby';

export default function LongestFirstPage() {
  React.useEffect(() => {
    navigate('/longest');
  });

  return null;
}
